<div id="selfdeclaretion-container">

    <h1 class="mat-h1">{{'selfDeclaretion' | translate | uppercase}}</h1>

    <p>{{'article' | translate }}</p>

    <a>{{'undersigned'|translate}} {{firstname}} {{lastname}} </a>
    <a>{{'enterOnlyCity'|translate}} </a>
    <a>{{role|translate}}</a>

    <p>{{'aware'|translate}}</p>

    <h2 class="mat-h2">{{'responsability'|translate|uppercase}}</h2>

    <ul>
        <li>
            {{'forty'|translate}}
        </li>
        <li>
            {{'fever'|translate}}
        </li>
        <li>
            {{'last14days'|translate}}
        </li>
        <li>
            {{'containmentsMeasures'|translate}}
        </li>
    </ul>

    <p>{{'forSupplierCustomerOther'|translate}}</p>

    <p><a>{{'undersigned'|translate}} {{firstname}} {{lastname}} </a><a>{{'undertakesToPrompty'|translate}}</a></p>

    <div class="assignment">
        <div>
            <p>{{'dateAndPlace'|translate}}</p>
            <p>{{currentDay}}</p>
        </div>

        <div>
            <p>{{'signatureOfDeclarant'|translate}}</p>
            <p> {{firstname}} {{lastname}} </p>
        </div>
    </div>

    <div class="container-button">
        <button mat-flat-button id="decline" (click)="close()">{{'reject'|translate|uppercase}}</button>
        <button mat-flat-button (click)="confirm()">{{'accept'|translate|uppercase}}</button>
    </div>


</div>