<form [formGroup]="form">

    <div id="title">
        <h1 class="mat-display-1">{{ 'welcome' | translate }}</h1>

        <button mat-flat-button color="accent" [routerLink]="['/guests']">CHECK OUT</button>
    </div>

    <mat-form-field class="third">

        <input matInput formControlName="firstname" placeholder="{{ 'name' | translate }} *" autocomplete="off">

        <mat-error *ngIf="form.get('firstname').errors && form.get('firstname').errors['required']">{{'requiredName' | translate}}</mat-error>

    </mat-form-field>

    <mat-form-field class="third">

        <input matInput formControlName="lastname" placeholder="{{ 'surname' | translate }} *" autocomplete="off">

        <mat-error *ngIf="form.get('lastname').errors && form.get('lastname').errors['required']">{{'requiredSurname' | translate}}</mat-error>

    </mat-form-field>

    <mat-form-field class="third">

        <input matInput formControlName="companyName" placeholder="{{ 'companyOrigin' | translate }} *" autocomplete="off">

        <mat-error *ngIf="form.get('companyName').errors && form.get('companyName').errors['required']">{{'requiredCompany' | translate}}</mat-error>

    </mat-form-field>

    <div class="third">
        <p>{{'enterOnlyCity'|translate}}</p>

        <mat-radio-group formControlName="role" (change)="check($event.value)">
            <a style="margin-right: 12px;">
                <mat-radio-button value="supplier">
                    {{'supplier'|translate}}
                </mat-radio-button>
            </a>
            <a style="margin-right: 12px;">
                <mat-radio-button value="customer">
                    {{'customer'|translate}}
                </mat-radio-button>
            </a>
            <a style="margin-right: 12px" id="other">
                <mat-radio-button value="other">
                    {{'other'|translate}}
                    <textarea matInput rows="1" style="background-color: #fafafa;" *ngIf="isOther" formControlName="otherTextArea"></textarea>
                </mat-radio-button>
            </a>

            <mat-error *ngIf="isRole && form.get('role').hasError('required')" style="position: relative;top: 8px;">{{ 'requiredRole' | translate }}</mat-error>

        </mat-radio-group>

    </div>

    <mat-form-field class="third">

        <input matInput formControlName="currentDay" placeholder="{{'validToDate' | translate}} *" autocomplete="off" type="date">

        <mat-error *ngIf="form.get('currentDay').errors && form.get('currentDay').errors['required']">{{'requiredPresentDay'|translate}}</mat-error>

    </mat-form-field>

    <mat-form-field class="third">
        <input matInput placeholder="{{ 'appointment' | translate }}" formControlName="account" [matAutocomplete]="auto" autocomplete="off">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnAutocomplete">
            <mat-option *ngFor="let item of accounts" [value]="item">
                {{ item.lastname }} {{ item.firstname }} ({{ item.company }})
            </mat-option>
        </mat-autocomplete>

        <mat-hint>{{'surnameHost' | translate}}</mat-hint>

        <mat-progress-bar *ngIf="accountLoading" mode="indeterminate"></mat-progress-bar>

        <mat-error *ngIf="form.get('account').errors && form.get('account').errors['required']">{{ 'requiredAppointment' | translate}}</mat-error>

    </mat-form-field>

    <div class="full" style="margin-top: 12px;">
        <div id="privacy-container">
            <mat-checkbox id="privacy" color="warn" formControlName="privacy"></mat-checkbox>
            <label for="privacy-input">{{ 'privacy1' | translate }} <a routerLink="/t&c" target="blank">{{'privacyPolicy' | translate}}</a> {{ 'privacy2' | translate }} </label>
        </div>

        <mat-error *ngIf="form.get('privacy').touched && form.get('privacy').hasError('required')">{{ 'requiredPrivacy' | translate }}</mat-error>
    </div>

    <div id="actions">

        <button mat-flat-button color="warn" *ngIf="!loading" (click)="openDialog()">{{ 'confirm' | translate | uppercase }}</button>

        <mat-progress-spinner *ngIf="loading === true" mode="indeterminate" diameter="50" color="warn"></mat-progress-spinner>

    </div>

</form>