<div id="guests-panel">

    <div id="title">
        <h1 class="mat-display-1">{{ 'companyGuest' | translate }}</h1>

        <button mat-flat-button color="accent" [routerLink]="['/home']">{{ 'registerGuest' | translate | uppercase }}</button>
    </div>

    <form id="search-guest-form" [formGroup]="form" (ngSubmit)="searchGuest(form.value)">

        <mat-form-field class="third">

            <input matInput formControlName="firstname" placeholder="{{ 'name' | translate }}" autocomplete="off">

            <mat-error *ngIf="form.get('firstname').errors && form.get('firstname').errors['required']">{{ 'requiredName' | translate }}</mat-error>

        </mat-form-field>

        <mat-form-field class="third">

            <input matInput formControlName="lastname" placeholder="{{ 'surname' | translate }}" autocomplete="off">

            <mat-error *ngIf="form.get('lastname').errors && form.get('lastname').errors['required']">{{ 'requiredSurname' | translate }}</mat-error>

        </mat-form-field>

        <div class="third search">
            <button mat-flat-button color="primary" type="submit">{{ 'search' | translate }}</button>
        </div>

    </form>

    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <mat-table [dataSource]="dataSource" *ngIf="dataSource.data && dataSource.data.length > 0">

        <ng-container matColumnDef="firstname">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'name' | translate | uppercase }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.firstname }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastname">
            <mat-header-cell *matHeaderCellDef mat-sort-header> 'surname' | translate | uppercase </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.lastname }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="companyName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'company'|translate|uppercase}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.companyName }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="account">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'guestOf' | translate | uppercase }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.account }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="checkIn">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{'entrance' | translate | uppercase }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.checkIn | date:'dd/MM/yyyy HH:mm' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">

                <div id="buttons">
                    <button mat-flat-button color="warn" *ngIf="!actionsLoading[element.id]" (click)="checkOut(element.id)">CHECK OUT</button>

                    <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="actionsLoading[element.id]"></mat-progress-spinner>
                </div>

            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="['firstname', 'lastname', 'companyName', 'account', 'checkIn', 'actions']"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['firstname', 'lastname', 'companyName', 'account', 'checkIn', 'actions'];"></mat-row>

    </mat-table>

    <p *ngIf="(!dataSource.data || dataSource.data.length === 0) && searched">{{'noGuest' | translate}} '{{ form.value.firstname }} {{ form.value.lastname }}' in azienda</p>

    <!-- <mat-paginator #paginator [length]="total" [pageSize]="recordsPerPage"></mat-paginator> -->

</div>