<div id="terms-container">

    <h1>INFORMATIVA PRIVACY AI SENSI DELL’ART. 13 DEL REGOLAMENTO UE 2016/679
        per i visitatori</h1>

    <p>Per noi la protezione dei dati è un argomento molto serio per cui desideriamo informarLa in merito alle modalità
        con le quali i
        dati vengono trattati ed ai diritti che Lei può esercitare ai sensi della vigente normativa sulla protezione dei
        dati, in particolare
        del Regolamento UE 2016/679 (di seguito anche: “GDPR”).</p>


    <ol>
        <li>
            <strong>TITOLARE DEL TRATTAMENTO E DPO</strong>
            <div class="center">
                <div>
                    <p>Titolare del trattamento<br>
                        Epipoli S.p.A.<br>
                        Viale Edoardo Jenner, 53,<br>
                        20159 Milano (MI),<br>
                        Dati di contatto email: <a href="mailto:gdpr@epipoli.com">gdpr@epipoli.com</a></p>
                </div>
                <div>
                    Dati di contatto del<br>
                    Data Protection Officer (DPO)<br>
                    Dati di contatto email: <a href="mailto:dpo@epipoli.com">dpo@epipoli.com</a>
                </div>

            </div>
        </li>

        <li>
            <strong>Le categorie di dati che vengono sottoposti a trattamento</strong>
            <p>Le categorie di “dati personali” (ex. Art. 4.1 del GDPR) trattati dal Titolare possono essere, a mero
                titolo esemplificativo
                ma certo non esaustivo:</p>
            <ul>
                <li>
                    Dati anagrafici ed identificativi (nome, cognome, ecc.);
                </li>
                <li>
                    Data e ora e altri dati non particolari connessi alla visita (a titolo esemplificativo: motivo della
                    visita e/o azienda
                    di provenienza).<br><br>
                </li>
            </ul>
        </li>

        <li>
            <strong>Finalità e basi legali del trattamento dei dati personali</strong>
            <p>Il trattamento dei dati personali avviene in conformità alle disposizioni del Regolamento generale sulla
                protezione dei dati
                (GDPR) e di ogni altra normativa sulla protezione dei dati applicabile. Di seguito vengono forniti i
                dettagli:</p>
            <ol>
                <li>
                    <strong>3.1 Finalità volte all'adempimento di un obbligo legale (ex art. 6 paragrafo 1 (c) del
                        GDPR)</strong>
                    <p>Il trattamento dei dati personali è volto a consentire l’accesso e la visita dei locali e delle
                        pertinenze del Titolare ai visitatori,
                        per la finalità seguente: <br></p>
                    <p>a. Applicazione delle norme in ambito di sicurezza sul lavoro (ex. D.lgs. 81/08) e di quelle ad
                        esse richiamate (a mero
                        titolo esemplificativo ma non certo esaustivo normativa antincendio e primo soccorso).</p>
                    <p>Il periodo di conservazione dei dati personali, relativamente alle finalità di cui alla presente
                        sezione è:
                        Per la finalità: a, per il tempo strettamente necessario, anche per esigenze organizzative
                        connesse allo smaltimento dei
                        registri, e comunque non oltre 1 mese dalla registrazione.
                        Tali termini potranno estendersi in conseguenza di speciali esigenze di ulteriore conservazione
                        nei limiti previsti dalla
                        normativa vigente</p>
                </li>
                <li>
                    <strong>3.2 Finalità per il perseguimento di un legittimo interesse (ex art. 6 paragrafo 1 (f) del
                        GDPR]</strong><br><br>
                    Si ricorda che i dati forniti potranno essere trattati per la tutela di interessi legittimi del
                    titolare ed in particolare per.<br>
                    a. Esigenze di sicurezza dei luoghi di lavoro e adempimento di procedure o istruzioni in ambito di
                    sicurezza sul lavoro;<br>
                    b. Registrazione, gestione e conservazione del dato personale tramite registri per la gestione degli
                    ingressi;<br>
                    c. Tutela del patrimonio aziendale.
                    <p>Il periodo di conservazione dei dati personali, relativamente alle finalità di cui alla presente
                        sezione è:
                        Per le finalità: a, b, c, per il tempo strettamente necessario, per perseguire le suddette
                        finalità, nonché per esigenze
                        organizzative connesse allo smaltimento dei registri, e comunque non oltre la settimana.
                        Tali termini potranno estendersi in conseguenza di speciali esigenze di ulteriore conservazione
                        nei limiti previsti dalla
                        normativa vigente</p>
                </li>
            </ol>
        </li>
        <li>
            <strong>Destinatari o categorie di destinatari dei dati personali (ex art. 13 paragrafo 1 (e) del
                GDPR) *</strong>
            <p>Nell'ambito delle suindicate finalità, il Titolare del trattamento potrà comunicare i Suoi dati a:</p>
            <p></p>
            <ul>
                <li>
                    Uffici e funzioni interne del Titolare medesimo;
                </li>
                <li>
                    RSPP;
                </li>
                <li>
                    Organi di controllo e Vigilanza;
                </li>
                <li>
                    Fornitori che prestano servizi di sicurezza;
                </li>
                <li>
                    Eventuali pubbliche amministrazioni e autorità pubbliche, qualora fosse richiesto dalle stesse
                    nell’ambito delle loro
                    attività istituzionali.<br>
                    * Maggiori informazioni sui Destinatari (ex art. 4.9 del GDPR) sono disponibili presso il Titolare
                    del trattamento dei
                    dati personali ai recapiti sopra indicati.<br><br>
                </li>
            </ul>
        </li>
        <li>
            <strong>Destinatari o categorie di destinatari dei dati personali (ex art. 13 paragrafo 1 (f) del
                GDPR) e trasferimento dei dati in Paesi extra UE</strong>
            <p>Il Titolare Le comunica che non ha intenzione di trasferire i suoi dati in paesi non compresi nella UE e
                nella SEE per le
                finalità sopra indicate.<br>
                * Maggiori informazioni sui Destinatari (ex art. 4.9 del GDPR) sono disponibili presso il Titolare del
                trattamento dei dati
                personali ai recapiti sopra indicati</p>
        </li>
        <li>
            <strong>Diritti del Soggetto Interessato (ex. art. 13 paragrafo 2 (b) del GDPR)</strong><br><br>
            L’interessato può far valere i seguenti diritti:<br><br>
            <ul>
                <li>
                    diritto di accesso dell’interessato [art. 15 del Regolamento UE] (la possibilità di essere informato
                    sui trattamenti effettuati
                    sui propri Dati Personali ed eventualmente riceverne copia);
                </li>
                <li>
                    diritto di rettifica dei propri Dati Personali [art. 16 del Regolamento UE] (l’interessato ha
                    diritto alla rettifica dei dati
                    personali inesatti che lo riguardano);
                </li>
                <li>
                    diritto alla cancellazione dei propri Dati Personali senza ingiustificato ritardo (“diritto
                    all’oblio”) [art. 17 del Regolamento
                    UE] (l’interessato ha, così come avrà, diritto alla cancellazione dei propri dati);
                </li>
                <li>
                    diritto di limitazione di trattamento dei propri Dati Personali nei casi previsti dall’art. 18 del
                    Regolamento UE, tra cui nel
                    caso di trattamenti illeciti o contestazione dell’esattezza dei Dati Personali da parte
                    dell’interessato [art. 18 del
                    Regolamento UE];
                </li>
                <li>
                    diritto alla portabilità dei dati [art. 20 del Regolamento UE], l’interessato potrà richiedere in
                    formato strutturato i propri
                    Dati Personali al fine di trasmetterli ad altro titolare, nei casi previsti dal medesimo articolo;
                </li>
                <li>
                    diritto di opposizione al trattamento dei propri Dati Personali [art. 21 del Regolamento UE]
                    (l’interessato ha, così come
                    avrà, diritto alla opposizione del trattamento dei propri dati personali);
                </li>
                <li>
                    diritto di non essere sottoposto a processi decisionali automatizzati, [art. 22 del Regolamento UE]
                    (l’interessato ha, così
                    come avrà, diritto a non essere sottoposto ad una decisione basata unicamente sul trattamento
                    automatizzato).
                    Ulteriori informazioni circa i diritti dell’interessato potranno ottenersi, richiedendo al Titolare
                    estratto integrale degli
                    articoli sopra richiamati.<br>
                    I suddetti diritti possono essere esercitati secondo quanto stabilito dal Regolamento inviando,
                    anche, una e-mail al seguente
                    indirizzo <a href="mailto:gdpr@epipoli.com">gdpr@epipoli.com</a>.<br>
                    In ossequio all’art. 19 del Regolamento UE, il Titolare procede a informare i destinatari cui sono
                    stati comunicati i dati
                    personali, le eventuali rettifiche, cancellazioni o limitazioni del trattamento richieste, ove ciò
                    sia possibile<br>
                </li>
            </ul>
        </li>
        <li>
            <strong>Diritto di proporre reclamo (ex art. 13 paragrafo 2 (d) del GDPR)</strong>
            <p>L’interessato, qualora ritenga che i propri diritti siano stati compromessi, ha diritto di proporre
                reclamo all’Autorità
                Garante per la protezione dei dati personali, secondo le modalità indicate dalla stessa Autorità al
                seguente indirizzo
                Internet <a href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/4535524"
                    target="_blank">http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/4535524</a>
                oppure
                inviando
                comunicazione scritta all’ Autorità Garante per la Protezione dei Dati Personali.</p>
        </li>

        <li>
            <strong>Possibile conseguenza della mancata comunicazione dei dati, natura del conferimento dei dati (ex
                art. 13 paragrafo 2 (e) del GDPR)
            </strong><br><br>
            <ol>
                <li>
                    <strong>8.1 In caso di adempimento di obblighi di legge o contrattuali</strong><br>
                    Si informa che qualora le finalità di trattamento abbiano come base giuridica un obbligo legale o
                    contrattuale (o anche
                    precontrattuale), l’interessato deve necessariamente fornire i dati richiesti.
                    In caso contrario vi sarà l’impossibilità da parte del Titolare di procedere al perseguimento delle
                    specifiche finalità di
                    trattamento
                </li>
                <li>
                    <strong>8.2 In caso di perseguimento di legittimo interesse</strong><br>
                    Parimenti, relativamente alle finalità basate su un interesse legittimo e che non richiedono il
                    consenso, l’opposizione
                    dell’interessato comporta o comporterà l’impossibilità di procedere alla realizzazione delle
                    rispettive finalità e degli
                    eventuali servizi connessi, per cui rispettivamente l’interessato si è opposto, fatti salvi motivi
                    legittimi cogenti prevalenti
                    del Titolare o finalizzati alla tutela di diritti in sede giudiziaria

                    <p>
                        Quando i dati non sono più necessari per perseguire le finalità sopra indicate, questi vengono
                        regolarmente cancellati,
                        qualora la loro cancellazione risulti impossibile o possibile solo a fronte di uno sforzo
                        sproporzionato a causa di una
                        modalità di conservazione particolare il dato non potrà essere trattato e dovrà essere
                        archiviato in aree non accessibili.
                    </p>
                </li>
            </ol>
        </li>
        <li>
            <strong>Esistenza di un processo decisionale automatizzato (profilazione inclusa)</strong><br><br>
            È attualmente escluso l'uso di processi decisionali meramente automatizzati come dettagliato dall'articolo
            22 del GDPR.
            Se in futuro si decidesse di istituire tali processi per casi singoli, l'interessato ne riceverà notifica in
            separata sede qualora
            ciò sia previsto dalla legge o aggiornamento della presente informativa<br><br>
        </li>
        <li>
            <strong>Modalità del trattamento</strong><br><br>
            I dati personali verranno trattati in forma informatizzata e telematica ed inseriti nelle pertinenti banche
            dati cui potranno
            accedere, e quindi venirne a conoscenza, gli addetti espressamente designati dal Titolare quali Responsabili
            ed Autorizzati
            del trattamento dei dati personali, che potranno effettuare operazioni di consultazione, utilizzo,
            elaborazione, raffronto
            ed ogni altra opportuna operazione anche automatizzata nel rispetto delle disposizioni di legge necessarie a
            garantire,
            tra l'altro, la riservatezza e la sicurezza dei dati nonché l’esattezza, l’aggiornamento e la pertinenza dei
            dati rispetto alle
            finalità dichiarate.
        </li>

    </ol>

</div>