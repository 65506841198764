<!-- Toolbar -->
<div id="banner">

    <div class="navbar">
        <div>
            <img src="assets/logo.png">
        </div>

        <div id="lang-menu"><a mat-button (click)="changeLang('it')">IT</a>
            <a>|</a><a mat-button (click)="changeLang('en')">EN</a></div>
    </div>


    <div id="text">

        <h1 class="mat-display-2">{{ 'guestRegister' | translate | uppercase }}</h1>

        <div id="red-line"></div>

    </div>



</div>

<router-outlet></router-outlet>

<div id="logos">

    <img src="assets/groupalia.png">

    <img src="assets/mygiftcard.png">

    <img src="assets/promocard.png">

    <img src="assets/change.png">

    <img src="assets/digitalways.png">

    <img src="assets/reward.png">

</div>