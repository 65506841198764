import { Component, OnInit, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, map, switchMap, catchError } from 'rxjs/operators';
import { HttpParams, HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { SelfDeclarationComponent } from '../self-declaration/self-declaration.component';
import { ShareService } from '../services/share.service';
import { Guest } from '../beans/guest';
import * as _moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  form: FormGroup;
  loading: boolean;
  accounts: { firstname: string, lastname: string, company: string }[];
  selectedAccount: { firstname: string, lastname: string, company: string };
  accountLoading: boolean;
  isRole: boolean;
  isOther: boolean;

  constructor(
    private shareService: ShareService,
    public dialog: MatDialog,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      currentDay: [null, Validators.required],
      role: [null, Validators.required],
      otherTextArea: [null],
      companyName: [null, Validators.required],
      account: [null, Validators.required],
      privacy: [null, Validators.requiredTrue]
    });

    this.isRole = false;
    this.isOther = false;
  }

  ngOnInit(): void {
    this.shareService.onChangeConfirm().subscribe(res => {
      let guest: Guest = this.shareService.getGuest();

      this.loading = true;
      let accountId = +this.form.get('account').value['id'];
      let data = {
        firstname: guest.name,
        lastname: guest.surname,
        role: guest.role,
        otherTextArea: guest.otherTextArea,
        currentDay: _moment.utc(this.form.get('currentDay').value).format('YYYY-MM-DD'),
        companyName: guest.companyName,
        accountId: accountId
      }

      this.httpClient.post(`/api/guest`, data).subscribe(
        (res) => {
          this.snackBar.open("Ospite registrato", "", { duration: 2000, verticalPosition: 'top', panelClass: 'success-text' }).afterDismissed().subscribe(() => window.location.reload());
        },
        (error) => {
          this.loading = false;
          this.snackBar.open(error, "", { duration: 2000, verticalPosition: 'top', panelClass: 'error-text' });
        }
      );

    });


    this.form.get('account').valueChanges
      .pipe(
        debounceTime(300),
        switchMap((value) => {
          if (typeof value === 'string' && value.length > 2) {
            this.accountLoading = true;

            let params = new HttpParams()
              .append('name', value)
              .append('limit', '10');

            return this.httpClient.get<{ size: number, results: { firstname: string, lastname: string, company: string }[] }>(`/api/account`, { params: params });
          } else if (value['id'])
            this.selectedAccount = value;

          return [];
        }),
        map(data => {
          this.accountLoading = false;

          return data.results;
        }),
        catchError(error => {
          this.accountLoading = false;
          return [];
        })
      ).subscribe(data => this.accounts = data);
  }

  public displayFnAutocomplete(option: { firstname: string, lastname: string, company: string }): string {
    return option ? `${option.lastname} ${option.firstname} (${option.company})` : undefined;
  }

  public openDialog() {
    let guest: Guest = new Guest();
    guest.name = this.form.get('firstname').value;
    guest.surname = this.form.get('lastname').value;
    guest.companyName = this.form.get('companyName').value;
    guest.role = this.form.get('role').value;
    guest.otherTextArea = this.form.get('otherTextArea').value;
    guest.currentDay = _moment.utc(this.form.get('currentDay').value).format('DD-MM-YYYY');
    this.isRole = guest.role != null ? false : true;
    this.shareService.setGuest(guest);
    this.form.get('privacy').markAsTouched();

    if (this.form.get('account').value != null && !this.form.get('account').value['id'])
      this.form.get('account').setValue("");

    if (this.form.valid)
      this.dialog.open(SelfDeclarationComponent);
  }

  public check(value) {
    this.isOther = value === "other";
    if (this.isOther) {
      document.getElementById('other').style.position = "relative";
      document.getElementById('other').style.top = "6px"
    }
    else {
      document.getElementById('other').style.position = "static";
      this.form.get('otherTextArea').setValue("");
    }

  }

}
