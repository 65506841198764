<div id="privacy-container">

    <h1>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI</h1>

    <p>Con la presente informativa, <strong>Epipoli S.p.A.</strong> vi informa su come tratterà i vostri dati personali nel rispetto della normativa applicabile in materia di protezione dei dati personali (art. 13 Regolamento UE n. 2016/679 "GDPR", e successive
        modifiche).
    </p>

    <ol>

        <li>
            <h2>TITOLARE DEL TRATTAMENTO</h2>
            <p>Il titolare del trattamento è Epipoli S.p.A. con sede in Via Edoardo Jenner, 53 – 20159 Milano (il "Titolare").</p>
        </li>

        <li>
            <h2>QUALI DATI VERRANNO TRATTATI?</h2>
            <p>I dati trattati saranno quelli da voi inseriti nel modulo di registrazione per l’ingresso in struttura. In particolare:</p>
            <ul>
                <li>Nome e cognome</li>
                <li>Azienda di provenienza</li>
                <li>Tempo di permanenza (ora di ingresso e ora di uscita)</li>
                <li>Persona ospitante</li>
                <li>Azienda ospitante</li>
            </ul>
        </li>

        <li>
            <h2>PER QUALI MOTIVI TRATTIAMO I VOSTRI DATI PERSONALI?</h2>
            <p>Il trattamento dei vostri dati personali sarà improntato ai principi di correttezza, liceità e trasparenza, tutelando la vostra riservatezza e i vostri diritti, in conformità alle procedure privacy aziendali.</p>
            <p>Tratteremo i vostri dati personali unicamente per perseguire nostro interesse legittimo a tutelare la sicurezza in azienda e il patrimonio aziendale attraverso la pronta individuazione di tutti gli ospiti presenti in struttura nel corso di
                eventuali situazioni d’emergenza.
            </p>
        </li>

        <li>
            <h2>FORNIRE I DATI PERSONALI È OBBLIGATORIO?</h2>
            <p>Il conferimento dei dati può essere negato, ma in caso di rifiuto, non potremo assicurare l’ingresso in struttura. Resta salvo il vostro diritto di opposizione di cui al punto 8.</p>
        </li>

        <li>
            <h2>COME VERRANNO TRATTATI E COME PROTEGGIAMO I VOSTRI DATI?</h2>
            <p>I vostri dati personali saranno sottoposti a trattamento elettronico. Adottiamo misure di sicurezza logiche, organizzative e tecniche, per assicurare ragionevolmente la protezione dei dati personali trattati.</p>
            <p>I dati non saranno in ogni caso oggetto di trasferimento al di fuori dell’Unione Europea. </p>
        </li>

        <li>
            <h2>PER QUANTO TEMPO SARANNO TRATTATI I VOSTRI DATI?</h2>
            <p>I dati da voi forniti verranno conservati per i tempi strettamente necessari alle finalità prospettate. Di conseguenza, al termine della visita, i vostri dati verranno eliminati o resi in forma anonima.</p>
        </li>

        <li>
            <h2>QUALI SONO I POSSIBILI DESTINATARI DEI SUOI DATI PERSONALI?</h2>
            <p>I vostri dati potranno essere resi accessibili, per le finalità di cui sopra, ai seguenti soggetti:</p>
            <ul>
                <li>Al personale di reception, nelle fasi di registrazione all’ingresso e all’uscita dalla struttura;</li>
                <li>Alla persona ospitante, ossia la persona interna all’azienda incaricata di ricevere l’ospite;</li>
                <li>Ad altre aziende del gruppo con sede in struttura, ma unicamente per finalità amministrative interne;</li>
                <li>A organismi di vigilanza e Autorità giudiziarie, ove richiesto dalla legge;</li>
            </ul>
            <p>I vostri dati non saranno in ogni caso diffusi.</p>
        </li>

        <li>
            <h2>I VOSTRI DIRITTI PRIVACY</h2>
            <p>In qualunque momento potrete conoscere i dati che vi riguardano, sapere come sono stati acquisiti, verificare se sono esatti, completi, aggiornati, e far valere i vostri diritti al riguardo. In particolare, avete il diritto:</p>
            <ol type="i">
                <li>di accesso ai vostri dati: ovvero conoscere quali informazioni su di voi trattiamo, perché e come; e ricevere una copia dei dati personali oggetto di trattamento;</li>
                <li>alla portabilità dei dati, e quindi di ottenere una copia dei vostri dati se trattati con strumenti elettronici, con il vostri consenso o nell’ambito di un rapporto contrattuale;</li>
                <li>di rettifica, in caso di dati inesatti che vi riguardano;</li>
                <li>di cancellazione dei vostri dati (diritto all’oblio), in talune circostanze (per es. se i dati non sono più necessari rispetto alle finalità per le quali sono stati raccolti oppure se revocate il consenso su cui si basa il trattamento);</li>
                <li>di opposizione, laddove il trattamento dei dati fosse basato sul perseguimento di un nostro legittimo interesse;</li>
                <li>di limitazione del trattamento, per esempio in caso di richieste di rettifica o di violazione dei presupposti di liceità del trattamento;</li>
                <li>di proporre reclamo al Garante Privacy.</li>
            </ol>
            <p>A tale scopo potete inviare una richiesta a: <a href="mailto:gdpr@epipoli.com">gdpr@epipoli.com</a> o scrivere al Titolare all’indirizzo sopra riportato.</p>
        </li>

    </ol>

</div>