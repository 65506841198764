import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  activeLang: 'it' | 'en';
  constructor(
    private translateService: TranslateService,
    private router: Router) {

    this.translateService.setDefaultLang('it');
    this.translateService.use(this.translateService.getBrowserLang());

    this.activeLang = 'it';
    if (this.translateService.getBrowserLang() === 'en')
      this.activeLang = 'en';

  }

  public changeLang(lang: 'it' | 'en'): void {
    this.translateService.use(lang);
    this.activeLang = lang;
  }

}