import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ShareService } from '../services/share.service';

@Component({
  selector: 'app-self-declaration',
  templateUrl: './self-declaration.component.html',
  styleUrls: ['./self-declaration.component.scss']
})
export class SelfDeclarationComponent implements OnInit {

  firstname: string;
  lastname: string;
  birthday: string;
  birthPlace: string;
  resident: string;
  role: string;
  currentDay: string;

  constructor(private shareService: ShareService, public dialogRef: MatDialogRef<SelfDeclarationComponent>) { }

  ngOnInit(): void {
    this.firstname = this.shareService.getGuest().name;
    this.lastname = this.shareService.getGuest().surname;
    this.birthday = this.shareService.getGuest().birthday;
    this.birthPlace = this.shareService.getGuest().birthPlace;
    this.resident = this.shareService.getGuest().resident;
    this.role = this.shareService.getGuest().role;
    this.currentDay = this.shareService.getGuest().currentDay;
  }

  public close() {
    this.dialogRef.close(true);
  }

  public confirm() {
    this.shareService.setConfirm(true);
    this.dialogRef.close(true);
  }

}
