export class Guest {
    name: string;
    surname: string;
    birthday: string;
    currentDay: string;
    birthPlace: string;
    role: string;
    otherTextArea: string;
    resident: string;
    companyName: string;
    account: string;
    privacy: boolean;
}