import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Guest } from '../beans/guest';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  private guest: Guest;
  private subjectGuest = new Subject<any>();
  private confirm: boolean;
  private subjectConfirm = new Subject<boolean>();

  public setConfirm(confirm: boolean) {
    this.confirm = confirm;
    this.subjectConfirm.next(this.confirm);
  }

  public onChangeConfirm(): Observable<boolean> {
    return this.subjectConfirm.asObservable();
  }

  public  setGuest(guest: Guest) {
    this.guest = guest;
    this.subjectGuest.next(this.guest);
  }

  public getGuest() {
    return this.guest;
  }

  public onChangeGuest(): Observable<any>  {
    return this.subjectGuest.asObservable();
  }

}
