import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { MatPaginator  } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { HttpParams, HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import * as _moment from 'moment';

@Component({
  selector: 'app-guests',
  templateUrl: './guests.component.html',
  styleUrls: ['./guests.component.scss']
})
export class GuestsComponent implements OnInit {

  dataSource: MatTableDataSource<{ id: number, firstname: string, lastname: string, companyName: string, checkIn: string, account: string }>;
  recordsPerPage: number;
  total: number = 100;

  forceReload: EventEmitter<boolean>;

  loading: boolean;
  actionsLoading: { [key: number]: boolean };

  checkOutTime: { [key: number]: string };
  visibleCheckOut: { [key: number]: boolean };

  form: FormGroup;
  searched: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private httpClient: HttpClient, private formBuilder: FormBuilder, public snackBar: MatSnackBar) {
    this.forceReload = new EventEmitter<boolean>();
    this.dataSource = new MatTableDataSource<{ id: number, firstname: string, lastname: string, companyName: string, checkIn: string, account: string }>([]);

    this.actionsLoading = {};
    this.checkOutTime = {};
    this.visibleCheckOut = {};

    this.recordsPerPage = 10;

    this.form = this.formBuilder.group({
      firstname: [null, Validators.required],
      lastname: [null, Validators.required]
    });
  }

  ngOnInit() {
    // this.paginator._intl.itemsPerPageLabel = 'Elementi mostrati per pagina: ';
    // this.paginator._intl.previousPageLabel = 'Pagina precedente';
    // this.paginator._intl.nextPageLabel = 'Pagina successiva';
    // this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
    //   if (length == 0 || pageSize == 0)
    //     return `0 di ${length}`;

    //   length = Math.max(length, 0);
    //   const startIndex = page * pageSize;
    //   const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    //   return `${startIndex + 1} - ${endIndex} di ${length}`;
    // }

    // merge(this.paginator.page, this.forceReload).pipe(
    //   startWith({}),
    //   switchMap(() => {
    //     this.loading = true;

    //     const offset = this.paginator.pageIndex * this.recordsPerPage;
    //     let params = new HttpParams()
    //       .append('start', offset.toString())
    //       .append('limit', this.recordsPerPage.toString());

    //     return this.httpClient
    //       .get<{ size: number, results: { id: number, firstname: string, lastname: string, companyName: string, checkIn: string, account: string }[] }>(`/api/guest`, { params: params });
    //   }),
    //   map(data => {
    //     this.loading = false;
    //     this.total = data.size;

    //     data.results.forEach(d => this.actionsLoading[d.id] = false);

    //     return data.results;
    //   }),
    //   catchError(error => {
    //     this.loading = false;

    //     setTimeout(() => {
    //       this.snackBar.open(error, "", { duration: 10000, verticalPosition: 'top', panelClass: 'snack-bar-error' });
    //     }, 0);
    //     return observableOf([]);
    //   })
    // ).subscribe(data => this.dataSource.data = data);

  }

  public searchGuest(values: { firstname: string, lastname: string }): void {
    if (this.form.valid) {
      this.loading = true;
      this.searched = false;

      this.dataSource.data = [];

      var params = new HttpParams()
        .append("firstname", values.firstname)
        .append("lastname", values.lastname);

      this.httpClient.get<{ size: number, results: any[] }>(`/api/guest/search`, { params: params }).subscribe(
        (res) => {
          if (res)
            this.dataSource.data = res.results;

          this.loading = false;
          this.searched = true;
        },
        (error) => {
          this.snackBar.open(error, "", { duration: 2000, verticalPosition: 'top', panelClass: 'error-text' });
          this.loading = false;
        }
      );
    }
  }

  public checkOut(id: number): void {
    this.actionsLoading[id] = true;
    this.httpClient.post(`/api/guest/${id}/checkOut`, {}).subscribe(
      (res) => {
        this.snackBar.open("CheckOut completato", "", { duration: 2000, verticalPosition: 'top', panelClass: 'success-text' }).afterDismissed().subscribe(() => window.location.reload());
      },
      (error) => {
        this.actionsLoading[id] = false;
        this.snackBar.open(error, "", { duration: 2000, verticalPosition: 'top', panelClass: 'error-text' });
      }
    );
  }

}
